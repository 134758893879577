import request from "../request";

export type FeedbackPayload = {
    message: string,
    replyTo: string,
    subject: string | null,
}

export const postFeedback = (data: FeedbackPayload) => request<FeedbackPayload>({
    method: "POST",
    uri: "feedback",
    data,
});
