import styled from "styled-components";

export const MainContainer = styled.div`
    // height: 100vh;
`;

export const OutletContainer = styled.main`
    margin-top: ${({ theme }) => theme.sizes.header.height};
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};

    @media ${({ theme }) => theme.media.medium} {
        margin-top: ${({ theme }) => theme.sizes.header.mobileHeight};
    }
`;
