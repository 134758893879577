import styled from "styled-components";
import { ReactComponent as LogoIcon } from "../../resources/icon/logo.svg";

export const HeaderContainer = styled.header`
    position: fixed;
    top: 0;
    height: ${({ theme }) => theme.sizes.header.height};
    width: 100%;

    display: flex;
    justify-content: space-between;

    padding: 20rem;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.black};

    z-index: 1000;

    ${({theme}) => `
        @media ${theme.media.medium} {
            padding: 16px;
            height: ${theme.sizes.header.mobileHeight};
        }
    `}
`;

export const LogoIconComponent = styled(LogoIcon)`
    height: 40rem;
    width: auto;

    ${({theme}) => `
        @media ${theme.media.medium} {
            height: 28px;
        }
    `}
`;
