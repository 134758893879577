import { Outlet } from "react-router-dom";
import { ReactLenis } from "@studio-freight/react-lenis";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { MainContainer, OutletContainer } from "./LayoutComponents";

const lenisOptions = {
    lerp: 0.1,
    duration: 1.5,
    smoothTouch: false, //smooth scroll for touch devices
    smooth: true,
};

const Layout = () => {
    return (
        <MainContainer>
            <Header/>
            <ReactLenis root options={lenisOptions}>
                <OutletContainer>
                    <Outlet/>
                </OutletContainer>
                <Footer/>
            </ReactLenis>
        </MainContainer>
    );
};

export default Layout;
