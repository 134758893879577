import { Link } from "react-router-dom";
import { HeaderContainer, LogoIconComponent } from "./HeaderComponents";

const Header = () => {
  return (
      <HeaderContainer>
          <Link to="/">
              <LogoIconComponent />
          </Link>
          {/* <div>Вакансии</div>
          <div>Культура</div>
          <div>Контакты</div> */}
      </HeaderContainer>
  )
};

export default Header;
