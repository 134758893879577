import { TFunction } from "i18next";
import * as yup from "yup";
import { RegExpressions } from "../../../../constants/regex";
import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_LENGTH } from "../../../../constants/validationRules";

export function feedbackValidation(t: TFunction<"translation", "main.feedbackForm">) {
    return yup
    .object({
        name: yup.string().max(MAX_FIELD_LENGTH, t(`validation.maxLength.${MAX_FIELD_LENGTH}`))
        .required(t("validation.required")),
        phone: yup.string().required(t("validation.required"))
        .matches(RegExpressions.phoneWithSpaces, t("validation.phone")),
        email: yup.string().required(t("validation.required"))
        .matches(RegExpressions.emailWithSpaces, t("validation.email"))
        .max(MAX_FIELD_LENGTH, t(`validation.maxLength.${MAX_FIELD_LENGTH}`)),
        position: yup.string().max(MAX_FIELD_LENGTH, t(`validation.maxLength.${MAX_FIELD_LENGTH}`))
        .required(t("validation.required")),
        about: yup.string().max(MAX_DESCRIPTION_FILED_LENGTH, t(`validation.maxLength.${MAX_DESCRIPTION_FILED_LENGTH}`))
        .required(t("validation.required"))
    });
}
