import { Trans, useTranslation } from "react-i18next";
import { SpanBase } from "../../../ui/text/SpanComponents";
import { Reveal } from "../../../ui/reveal/Reveal";
import {
    HeaderBlock,
    TechnologiesBlockContainer,
    TechnologiesItemsContainer, TechnologyItem
} from "./MainPageTechnologiesSectionComponents";
import ItemsCarousel from "./itemCarousel/TechnologiesItemsCarousel";
import { TechnologyItemType } from "./technolodiesTypes";

import { ReactComponent as ReactIcon } from "../../../../resources/icon/technologies/react.svg";
import { ReactComponent as ReactIconMobile } from "../../../../resources/icon/technologies/mobile/react.svg";
import { ReactComponent as JavaScriptIcon } from "../../../../resources/icon/technologies/javascript.svg";
import { ReactComponent as JavaScriptIconMobile } from "../../../../resources/icon/technologies/mobile/javascript.svg";
import { ReactComponent as TypeScriptIcon } from "../../../../resources/icon/technologies/typescript.svg";
import { ReactComponent as TypeScriptIconMobile } from "../../../../resources/icon/technologies/mobile/typescript.svg";
import { ReactComponent as NextJsIcon } from "../../../../resources/icon/technologies/nextjs.svg";
import { ReactComponent as NextJsIconMobile } from "../../../../resources/icon/technologies/mobile/nextjs.svg";
import { ReactComponent as AngularIcon } from "../../../../resources/icon/technologies/angular.svg";
import { ReactComponent as AngularIconMobile } from "../../../../resources/icon/technologies/mobile/angular.svg";

import { ReactComponent as KotlinIcon } from "../../../../resources/icon/technologies/kotlin.svg";
import { ReactComponent as KotlinIconMobile } from "../../../../resources/icon/technologies/mobile/kotlin.svg";
import { ReactComponent as SwiftIcon } from "../../../../resources/icon/technologies/swift.svg";
import { ReactComponent as SwiftIconMobile } from "../../../../resources/icon/technologies/mobile/swift.svg";
import { ReactComponent as ReactNativeIcon } from "../../../../resources/icon/technologies/reactnative.svg";
import { ReactComponent as ReactNativeIconMobile }
    from "../../../../resources/icon/technologies/mobile/reactnative.svg";
import { ReactComponent as DartIcon } from "../../../../resources/icon/technologies/dart.svg";
import { ReactComponent as DartIconMobile } from "../../../../resources/icon/technologies/mobile/dart.svg";
import { ReactComponent as JavaIcon } from "../../../../resources/icon/technologies/java.svg";
import { ReactComponent as JavaIconMobile } from "../../../../resources/icon/technologies/mobile/java.svg";
import { ReactComponent as ObjCIcon } from "../../../../resources/icon/technologies/objc.svg";
import { ReactComponent as ObjCIconMobile } from "../../../../resources/icon/technologies/mobile/objc.svg";

import { ReactComponent as CSharpIcon } from "../../../../resources/icon/technologies/csharp.svg";
import { ReactComponent as CSharpIconMobile } from "../../../../resources/icon/technologies/mobile/csharp.svg";
import { ReactComponent as PhpIcon } from "../../../../resources/icon/technologies/php.svg";
import { ReactComponent as PhpIconMobile } from "../../../../resources/icon/technologies/mobile/php.svg";
import { ReactComponent as PythonIcon } from "../../../../resources/icon/technologies/python.svg";
import { ReactComponent as PythonIconMobile } from "../../../../resources/icon/technologies/mobile/python.svg";
import { ReactComponent as GoLangIcon } from "../../../../resources/icon/technologies/golang.svg";
import { ReactComponent as GoLangIconMobile } from "../../../../resources/icon/technologies/mobile/golang.svg";
import { ReactComponent as NodeJsIcon } from "../../../../resources/icon/technologies/nodejs.svg";
import { ReactComponent as NodeJsIconMobile } from "../../../../resources/icon/technologies/mobile/nodejs.svg";

import { ReactComponent as PostgresSQLIcon } from "../../../../resources/icon/technologies/postgressql.svg";
import { ReactComponent as PostgresSQLIconMobile }
    from "../../../../resources/icon/technologies/mobile/postgressql.svg";
import { ReactComponent as ClickHouseIcon } from "../../../../resources/icon/technologies/clickhouse.svg";
import { ReactComponent as ClickHouseIconMobile } from "../../../../resources/icon/technologies/mobile/clickhouse.svg";
import { ReactComponent as RedisIcon } from "../../../../resources/icon/technologies/redis.svg";
import { ReactComponent as RedisIconMobile } from "../../../../resources/icon/technologies/mobile/redis.svg";
import { ReactComponent as LogstashIcon } from "../../../../resources/icon/technologies/logstash.svg";
import { ReactComponent as LogstashIconMobile } from "../../../../resources/icon/technologies/mobile/logstash.svg";
import { ReactComponent as KibabaIcon } from "../../../../resources/icon/technologies/kibana.svg";
import { ReactComponent as KibabaIconMobile } from "../../../../resources/icon/technologies/mobile/kibana.svg";
import { ReactComponent as ElasticSearchIcon } from "../../../../resources/icon/technologies/elasticsearch.svg";
import { ReactComponent as ElasticSearchIconMobile }
    from "../../../../resources/icon/technologies/mobile/elasticsearch.svg";

const animationDurationMap: Record<number, { key: string, duration: number} > = {
    1: { key: "javascript-typescript", duration: 5 },
    7: { key: "dart-java-objc", duration: 3 },
    8: { key: "csharp-php", duration: 4 },
    15: { key: "logstash-kibana-elasticsearch", duration: 2 },
}

const TECHNOLOGIES_ITEMS_LIST: Array<TechnologyItemType | Array<TechnologyItemType>> = [
    { key: "react", Icon: ReactIcon, IconMobile: ReactIconMobile },
    [
        { key: "javascript", Icon: JavaScriptIcon, IconMobile: JavaScriptIconMobile },
        { key: "typescript", Icon: TypeScriptIcon, IconMobile: TypeScriptIconMobile },
    ],
    { key: "nextjs", Icon: NextJsIcon, IconMobile: NextJsIconMobile },
    { key: "angular", Icon: AngularIcon, IconMobile: AngularIconMobile },

    { key: "kotlin", Icon: KotlinIcon, IconMobile: KotlinIconMobile },
    { key: "swift", Icon: SwiftIcon, IconMobile: SwiftIconMobile },
    { key: "reactnative", Icon: ReactNativeIcon, IconMobile: ReactNativeIconMobile },
    [
        { key: "dart", Icon: DartIcon, IconMobile: DartIconMobile },
        { key: "java", Icon: JavaIcon, IconMobile: JavaIconMobile },
        { key: "objc", Icon: ObjCIcon, IconMobile: ObjCIconMobile }
    ],
    [
        { key: "csharp", Icon: CSharpIcon, IconMobile: CSharpIconMobile },
        { key: "php", Icon: PhpIcon, IconMobile: PhpIconMobile },
    ],
    { key: "python", Icon: PythonIcon, IconMobile: PythonIconMobile },
    { key: "golang", Icon: GoLangIcon, IconMobile: GoLangIconMobile },
    { key: "nodejs", Icon: NodeJsIcon, IconMobile: NodeJsIconMobile },

    { key: "postgressql", Icon: PostgresSQLIcon, IconMobile: PostgresSQLIconMobile },
    { key: "clickhouse", Icon: ClickHouseIcon, IconMobile: ClickHouseIconMobile },
    { key: "redis", Icon: RedisIcon, IconMobile: RedisIconMobile },
    [
        { key: "logstash", Icon: LogstashIcon, IconMobile: LogstashIconMobile },
        { key: "kibana", Icon: KibabaIcon, IconMobile: KibabaIconMobile },
        { key: "elasticsearch", Icon: ElasticSearchIcon, IconMobile: ElasticSearchIconMobile },
    ],
];

const MainPageTechnologiesSection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "main.technologies"});

    return (
        <TechnologiesBlockContainer>
            <Reveal>
                <HeaderBlock>
                    <Trans
                        t={t}
                        i18nKey="header"
                        components={{ span: <SpanBase $color="primary_300" /> }}
                    />
                </HeaderBlock>
            </Reveal>
            <TechnologiesItemsContainer>
                {TECHNOLOGIES_ITEMS_LIST.map((item, index) =>
                    Array.isArray(item)
                        ? (
                            <ItemsCarousel
                                key={animationDurationMap[index].key}
                                items={item}
                                duration={animationDurationMap[index].duration}
                            />
                        )
                        : (
                            <TechnologyItem key={item.key}>
                                <item.Icon className="desktop" />
                                <item.IconMobile className="mobile" />
                            </TechnologyItem>
                        )
                )}
            </TechnologiesItemsContainer>
        </TechnologiesBlockContainer>
    );
};

export default MainPageTechnologiesSection;
