import styled from "styled-components";
import { Header2 } from "../../../ui/text/HeaderComponents";
import {Reveal} from "../../../ui/reveal/Reveal";


export const DirectionsBlockContainer = styled.section`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 40rem;

    padding: 0 40rem 256rem 40rem;
    margin-top: 72rem;
    box-sizing: border-box;

    ${({ theme }) => `
        @media ${theme.media.large} { 
            flex-direction: column;
            gap: 96rem;

            padding: 128rem 40rem 256rem 40rem;
            margin-top: 0;
        }
        
        @media ${theme.media.medium} { 
            padding: 96px 16px 128px 16px;
        }
    `};
`;

export const Header = styled(Header2)`
    position: sticky;
    top: ${({ theme}) => theme.sizes.header.height};
    padding: 48rem 0 16rem;
    font-weight: 700;
    height: 320rem;


    @media ${({ theme }) => theme.media.large } {
        padding: 0;
        height: unset;
        position: static;
        top: unset;
    }
`;

export const DirectionsContainer = styled.div`
    padding-top: 72rem;
    
    display: flex;
    flex-direction: column;
    gap: 128rem;

    ${({ theme }) => `
        @media ${theme.media.large} { 
            flex-direction: row;
            flex-wrap: wrap;
            gap: 64rem;
            
            padding-top: 0;
            
            & > :last-child {
                flex-basis: 100%;
            }
        }
        
        @media ${theme.media.medium} {
            gap: 64px;
        }
    `};
`;

export const DirectionItem = styled(Reveal)`
    width: 680rem;

    ${({ theme }) => `
        @media ${theme.media.large} { 
            width: unset;
            flex-basis: calc(50% - 64rem / 2);
        }
        
        @media ${theme.media.medium} { 
            flex: 1 1 280px;
        }
    `};
`;
