import { ChangeEvent, InputHTMLAttributes } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { FeedbackType, FieldName } from "../../pages/mainPage/feedbackFormSection/FeedbackFormSection";
import { FlexContainer } from "../СommonComponents";
import { InputField, InputErrorWrapper, InputWrapper } from "./InputComponents";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement>{
    fieldName: FieldName;
    title: string;
    placeholder: string;
    resetServerErrors?: () => void;
    fieldError?: FieldError;
}

const TextInput =  ({ title, placeholder, fieldName, fieldError, resetServerErrors }: TextInputProps) => {
    const { register, clearErrors } = useFormContext<FeedbackType>();
    
    const {onChange, ...otherProps} = register(fieldName)
    
    const fieldErrorMessage = fieldError?.message;
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event)
        clearErrors(fieldName);
        resetServerErrors?.();
    }
    
    
    return (
        <FlexContainer $flexDirection="column">
            <InputWrapper>
                {title}
                <InputField
                    type="text"
                    placeholder={placeholder}
                    {...otherProps}
                    $isError={!!fieldErrorMessage}
                    onChange={handleChange}
                />
            </InputWrapper>
            {fieldErrorMessage && <InputErrorWrapper>{fieldErrorMessage}</InputErrorWrapper>}
        </FlexContainer>
    );
}

export default TextInput;
