import { TechnologyItemType } from "../technolodiesTypes";
import { AnimatedTechnologyIcon, TechnologyItem } from "../MainPageTechnologiesSectionComponents";

interface ItemCarouselProps {
    items: Array<TechnologyItemType>;
    duration: number;
}

const ItemsCarousel = ({ items, duration }: ItemCarouselProps) => (
    <TechnologyItem>
        {items.map(({ key, Icon, IconMobile }, index) => (
            <AnimatedTechnologyIcon
                key={key}
                $duration={duration}
                $index={index}
                $count={items.length - 1}
            >
                <Icon className="desktop" />
                <IconMobile className="mobile" />
            </AnimatedTechnologyIcon>
        ))}
    </TechnologyItem>
);

export default ItemsCarousel;
