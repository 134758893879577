import {Navigate, Route, Routes} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Layout from "./components/layout/Layout";
import MainPage from "./components/pages/mainPage/MainPage";
import GlobalStyles from "./styles/global";
import { baseTheme } from "./styles/theme";


const App = () => (
    <ThemeProvider theme={baseTheme}>
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route path="/" element={<MainPage/>} />
                <Route path="*" element={<Navigate to="/" replace /> } />
            </Route>
        </Routes>
        <GlobalStyles />
    </ThemeProvider>
);

export default App;
