import { LoaderVariantType } from "./loaderTypes";
import { BackgroundLoaderCircle, MainLoaderCircle } from "./LoaderComponents";

interface LoaderProps {
    variant?: LoaderVariantType;
    size?: string;
}

const Loader = ({ variant = "lightSecondary", size = "20" }: LoaderProps) => (
    <svg height={size} width={size} viewBox="0 0 300 300">
        <MainLoaderCircle
            $variant={variant}
            cx="50%"
            cy="50%"
            r="40%"
            fill="none"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="280 150 150"
                to="640 150 150"
                dur="1s"
                repeatCount="indefinite"
            />
        </MainLoaderCircle>
        <BackgroundLoaderCircle
            $variant={variant}
            cx="50%"
            cy="50%"
            r="40%"
            fill="none"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 150 150"
                to="360 150 150"
                dur="1s"
                repeatCount="indefinite"
            />
        </BackgroundLoaderCircle>
    </svg>
);

export default Loader;
