import { ContactsBlock, FooterContainer, LogoIconComponent } from "./FooterComponents";
import {ParagraphS} from "../ui/text/ParagraphComponents";


const Footer = () => {
    return (
        <FooterContainer>
            <LogoIconComponent />
            <ContactsBlock>
                <ParagraphS>+375&nbsp;29&nbsp;651&nbsp;48&nbsp;79</ParagraphS>
                <ParagraphS>info@gofarsoft.org</ParagraphS>
            </ContactsBlock>
        </FooterContainer>
    );
};

export default Footer;
