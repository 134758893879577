import { useEffect, useRef, HTMLAttributes } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

interface RevealProps extends HTMLAttributes<HTMLDivElement> {
    delay?: number;
}

export const Reveal = ({ children, className, style, delay }: RevealProps)  => {
    const scrollContainerRef = useRef(null);
    const isInView = useInView(scrollContainerRef, { once: true });

    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView]);


    return (
        <motion.div
            ref={scrollContainerRef}
            variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: delay || 0 }}
            className={className}
            style={style}
        >
            {children}
        </motion.div>
    );
};
