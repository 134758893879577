import styled, { css } from "styled-components";
import { ButtonVariantType } from "./buttonTypes";

const filledButtonHoverStyle = css`
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary_500};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const tertiaryButtonHoverStyle = css`
  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.primary_500}`};
    color: ${({ theme }) => theme.colors.white};
  }
  
  &:disabled {
    &:hover {
      border: ${({ theme }) => `1px solid ${theme.colors.neutral_300}`};
    }
  }
`;


export const BaseButtonComponent = styled.button<{ $variant: ButtonVariantType, $isLoading?: boolean }>`
  display: flex;
  padding: 16rem 32rem;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 200px;
  border: none;

  font-size: 21rem;
  line-height: 21rem;
  text-transform: uppercase;
  
  ${({ theme }) => `
        @media ${theme.media.medium} {
            padding: 16px 32px;
            font-size: 21px;
            line-height: 21px;
        }
    `};

  ${({ theme, $variant, $isLoading }) => {
    if ($isLoading) {
      return css`
        background-color: ${theme.colors.primary_800};
        border: 1px solid ${theme.colors.primary_800};
        color: ${theme.colors.white};
        cursor: not-allowed;
        
        color: ${theme.colors.white};
        background-color: ${theme.colors.primary_800};
      `;
    } else {
      switch ($variant) {
        case "primary":
          return css`
            background-color: ${theme.colors.primary_300};
            color: ${theme.colors.white};
            
            ${filledButtonHoverStyle};
          `;
        case "lightSecondary":
          return css`
            background-color: ${theme.colors.white};
            color:  ${theme.colors.neutral_800};
            
            ${filledButtonHoverStyle};
          `;
        case "darkSecondary":
          return css`
            background-color: ${theme.colors.neutral_800};
            color: ${theme.colors.white};
            
            ${filledButtonHoverStyle};
          `;
        case "lightTertiary":
          return css`
            background-color: transparent;
            border: 1px solid ${theme.colors.neutral_800};
            color: ${theme.colors.neutral_800};
            
            ${tertiaryButtonHoverStyle}
          `;
        case "darkTertiary":
          return css`
            background-color: transparent;
            border: 1px solid ${theme.colors.white};
            color: ${theme.colors.white};
            
            ${tertiaryButtonHoverStyle}
          `;
        default:
          return "";
      }
    }
  }}
  
  &:active {
    background-color:${({theme}) => theme.colors.primary_800};
    color:  ${({theme}) => theme.colors.white};
  }
  
  &:disabled {
    color: ${({ theme }) => theme.colors.neutral_500};
    background-color: ${({ theme }) => theme.colors.neutral_300};
    cursor: not-allowed;

    &:hover {
      color: ${({ theme }) => theme.colors.neutral_500};
      background-color: ${({ theme }) => theme.colors.neutral_300};
    }

    svg {
      fill: ${({ theme }) => theme.colors.neutral_500};
    }
  }
`;
