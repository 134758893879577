import styled from "styled-components";
import { TextProps, textStyles } from "../../../styles/common";

const BaseParagraph = styled.p<TextProps>`
    ${textStyles}
`;

export const ParagraphS = styled(BaseParagraph)`
    font-size: 16rem;
    line-height: 19rem;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 16px;
            line-height: 19px;
        }
    `};
`;

export const ParagraphM = styled(BaseParagraph)`
    font-size: 18rem;
    line-height: 140%;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 16px;
        }
    `};
`;

export const ParagraphL = styled(BaseParagraph)`
    font-size: 21rem;
    line-height: 140%;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 21px;
        }
    `};
`;

export const ParagraphXL = styled(BaseParagraph)`
    font-size: 24rem;
    line-height: 120%;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 21px;
        }
    `};
`;

export const ParagraphHero = styled(BaseParagraph)`
    font-size: 96rem;
    line-height: 120%;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 56px;
        }
    `};
`;

export const ErrorParagraphS = styled(ParagraphS)`
  line-height: 140%;
  color: ${({ theme }) => theme.colors.error};
  font-weight: 400;
  
  text-align: center;
  margin-top: 16px;
`
