import styled from "styled-components";
import { TextProps, textStyles } from "../../../styles/common";

export const SpanBase = styled.span<TextProps>`
    ${textStyles}
`;

// 48px от 1440
export const SpanXXXL = styled(SpanBase)`
    font-size: 48rem;
    line-height: 120%;
`;
