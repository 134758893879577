import styled from "styled-components";
import { ReactComponent as CompanyNameIcon } from "../../../../resources/icon/company-name.svg";
import {Reveal} from "../../../ui/reveal/Reveal";

export const IntroductionBlockContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 96rem;

    box-sizing: border-box;
    padding: 72rem 40rem 128rem 40rem;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            gap: 48px;
            padding: 64px 16px 128px 16px;
        }
    `};
`;

export const CompanyName = styled(CompanyNameIcon)`
    height: 240rem;
    width: fit-content;
    
    ${({ theme }) => `
        @media ${theme.media.large} {
            height: 186rem;
        }
        
        @media ${theme.media.medium} {
            height: auto;
            width: 100%;
        }
    `};
`;

export const DescriptionContainer = styled.div`
    display: flex;
    justify-content: space-between;

    ${({ theme }) => `
        @media ${theme.media.medium} {
            display: block;
        }
    `};
`;

export const Description = styled(Reveal)`
    max-width: 390rem;

    color: ${({ theme }) => theme.colors.neutral_400};
    
    ${({ theme }) => `
        @media ${theme.media.large} {        
            max-width: 440rem;
        }
        
        @media ${theme.media.medium} {
            max-width: unset;
            width: 100%;
            
            margin-bottom: 32px; 
        }
    `};
`;

export const NumbersBlocksContainer = styled(Reveal)`
    max-width: 680rem;

    display: flex;
    flex-direction: row;
    gap: 48rem;
    
    ${({ theme }) => `
        @media ${theme.media.large} { 
            max-width: 472rem;
        }
        
        @media ${theme.media.medium} { 
            max-width: unset;
            width: 100%;
            
            gap: 32px;
        }
        
        @media ${theme.media.small} { 
            flex-direction: column;
        }
    `};
`;

export const NumberBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16rem;

    ${({ theme }) => `
        @media ${theme.media.large} { 
            flex-basis: calc(50% - 32rem / 2);
        }
        
        @media ${theme.media.large} { 
            flex-basis: unset;
            
            & > p:last-child {
                font-size: 18px;
            }
        }
     `};
`;
