import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useScroll, useTransform } from "framer-motion";
import {
    BackgroundContainer,
    DescriptionBlock,
    HeaderBlock,
    InvitationBlockContainer
} from "./MainPageInvitationSectionComponents";
import { Reveal } from "../../../ui/reveal/Reveal";

const MainPageInvitationSection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "main.invitation"});
    const parallaxContainerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: parallaxContainerRef,
        offset: ["start end", "end start"],
    });

    const y = useTransform(scrollYProgress, [0, 1], ["-20%", "0%"]);

    return (
        <InvitationBlockContainer ref={parallaxContainerRef}>
            <Reveal style={{ zIndex: 20 }}>
                <HeaderBlock>{t("header")}</HeaderBlock>
            </Reveal>
            <Reveal style={{ zIndex: 20 }} delay={0.5}>
                <DescriptionBlock $marginBottom={8} $weight={300}>
                    {t("description")}
                </DescriptionBlock>
                <DescriptionBlock $weight={300}>
                    {t("offer")}
                </DescriptionBlock>
            </Reveal>
            <BackgroundContainer style={{ y }} />
        </InvitationBlockContainer>
    );
};

export default MainPageInvitationSection;
