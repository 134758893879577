import styled from "styled-components";
import { ReactComponent as LogoIcon } from "../../resources/icon/logo.svg";

export const FooterContainer = styled.footer`
    height: ${({ theme }) => theme.sizes.footer.height};
    box-sizing: border-box;
    
    background-color: ${({ theme }) => theme.colors.black};
    padding: 9rem 40rem 64rem 40rem;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${({ theme }) => theme.media.medium} {        
        height: unset;
        padding: 9px 16px 64px 16px;
        flex-direction: column-reverse;
        align-items: center;
        gap: 32px
    }
`;

export const LogoIconComponent = styled(LogoIcon)`
    height: 60rem;
    width: auto;

    @media ${({ theme }) => theme.media.medium} {
        height: 60px;
    }
`;

export const ContactsBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8rem;
    
    height: 60rem;
    
    color: ${({ theme }) => theme.colors.white};

    @media ${({ theme }) => theme.media.medium} {
        height: unset;
        flex-direction: row;
        gap: 24px;
    }
`;
