import styled from "styled-components";

export const InputField = styled.input<{$isError?: boolean}>`
    padding: 11rem 8rem 11rem 0;
    outline: 0;
    border: none;
    border-bottom: 2rem solid ${({ theme, $isError }) =>
    $isError
        ? theme.colors.error
        :  theme.colors.neutral_400
    };
    background-color: transparent;
  
    font-size: 18rem;
    font-weight: 400;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.white};

    ::placeholder {
      color: ${({ theme }) => theme.colors.neutral_500};
      :focus {
        color: ${({ theme }) => theme.colors.white};
      }
    }

    ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 18px;
            padding: 11px 8px 11px 0;
    
        }
    `};
  
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8rem;

  font-size: 14rem;
  font-weight: 400;
  line-height: 20rem;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;

  ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 14px;
             line-height: 20px;
        }
    `};
`;

export const InputErrorWrapper = styled.div`
  margin-top: 4rem;

  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.error};
  line-height: 140%;

  ${({ theme }) => `
        @media ${theme.media.medium} {
            font-size: 14px;
        }
    `};
`;
