import { Trans, useTranslation } from "react-i18next";
import { CustomTypeOptions } from "i18next";
import { Reveal } from "../../../ui/reveal/Reveal";
import { SpanBase } from "../../../ui/text/SpanComponents";
import { ParagraphM } from "../../../ui/text/ParagraphComponents";
import { Header4 } from "../../../ui/text/HeaderComponents";
import {
    DirectionItem, DirectionsBlockContainer, DirectionsContainer, Header,
} from "./MainPageDirectionsSectionComponents";


// i18next doesn't have support for typing of translations with context that's why we need "as unknown"
const DIRECTIONS_ITEMS_LIST = [
    "paymentSystem", "antiFraud", "externalApi", "paymentApi",
    "modulesPlugins", "analyticsIntegration", "platformIntegration",
] as unknown as Array<keyof CustomTypeOptions["resources"]["translation"]["main"]["directions"]>;

const MainPageDirectionsSection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "main.directions"});

    return (
        <DirectionsBlockContainer>
            <Reveal>
                <Header>
                    <Trans
                        t={t}
                        i18nKey="experts"
                        components={{ span: <SpanBase $color="primary_300" /> }}
                    />
                </Header>
            </Reveal>
            <Reveal delay={0.5}>
                <DirectionsContainer>
                    {DIRECTIONS_ITEMS_LIST.map((itemName) => {
                        return (
                            <DirectionItem key={itemName}>
                                <Header4 $weight={600} $marginBottom={16}>
                                    {t(itemName, { context: "header" })}
                                </Header4>
                                <ParagraphM $color="neutral_400">{t(itemName, { context: "text" })}</ParagraphM>
                            </DirectionItem>
                        )})}
                </DirectionsContainer>
            </Reveal>
        </DirectionsBlockContainer>
    )
}

export default MainPageDirectionsSection;
