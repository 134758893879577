import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { parseServerError } from "../../../../utils/parseErrorsHelper";
import { FeedbackPayload, postFeedback } from "../../../../api/feedback/postFeedback";
import { ButtonXL } from "../../../ui/button/ButtonXL";
import TextInput from "../../../ui/input/TextInput";
import { Header2 } from "../../../ui/text/HeaderComponents";
import { ErrorParagraphS } from "../../../ui/text/ParagraphComponents";
import { FlexContainer } from "../../../ui/СommonComponents";
import {
    DescriptionBlock,
    FeedbackBlockContainer,
    FeedbackFormTitleSection, FormEntity, FormWrapper,
} from "./FeedbackFormSectionComponents";
import { feedbackValidation } from "./validation";

export type FieldName = "name" | "phone" | "email" | "position" | "about";

export type FeedbackType = {
    name: string,
    phone: string,
    email: string,
    position: string,
    about: string,
}

const emptyFeedbackFields: FeedbackType = {
    name: "",
    phone: "",
    email: "",
    position: "",
    about: "",
}

type FormTextsVariant = "success" | "hasntSubmitYet";

const formFeedbackMessage = (data: FeedbackType): FeedbackPayload => {
    return {
        message: [
            `Candidate name:\n ${data.name}`,
            `Phone:\n ${data.phone}`,
            `Email:\n ${data.email}`,
            `Desired position:\n ${data.position}`,
            `About him/her:\n ${data.about}`,
        ].join("\n\n"),
        replyTo: data.email,
        subject: "GoFarSoft feedback",
    }
}

const FeedbackFormSection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "main.feedbackForm"});

    const [isLoading, setIsLoading] = useState(false);
    const [serverErrors, setServerErrors] = useState<string[]>([]);

    const validationScheme = feedbackValidation(t);

    const methods = useForm<FeedbackType>({
        mode: "onBlur",
        defaultValues: emptyFeedbackFields,
        resolver: yupResolver(validationScheme)
    });

    const resetServerErrors = () => {
        setServerErrors([]);
    };

    const onSubmit: SubmitHandler<FeedbackType> = async (data: FeedbackType) => {
        setIsLoading(true);

        const formedMessage= formFeedbackMessage(data);

        try {
            await postFeedback(formedMessage);
        } catch (error) {
            const axiosError = error as AxiosError;
            const parsedErrorsFromServer = parseServerError(axiosError, t);
            const errorsMessagesList = Object.values(parsedErrorsFromServer);
            setServerErrors(errorsMessagesList);
        } finally {
            setIsLoading(false);
        }
    };

    const formText: FormTextsVariant = methods.formState.isSubmitSuccessful ? "success" : "hasntSubmitYet";

    useEffect(() => {
        if (methods.formState.isSubmitSuccessful) {
           methods.reset(undefined, { keepIsSubmitSuccessful : true });
        }
        if (serverErrors.length) {
            methods.reset();
        }
    }, [methods.formState.submitCount]);

    return (
        <FeedbackBlockContainer>

            <FeedbackFormTitleSection>
                <Header2>{t(`${formText}.header`)}</Header2>
                <DescriptionBlock>
                    {t(`${formText}.description`)}
                </DescriptionBlock>
            </FeedbackFormTitleSection>

            <FormProvider {...methods}>
                <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
                    <FormEntity>
                        <TextInput
                            title={t("fields.name")}
                            placeholder={t("placeholders.name")}
                            fieldName="name"
                            fieldError={methods.formState.errors.name}
                            resetServerErrors={resetServerErrors}
                        />
                        <TextInput
                            title={t("fields.phone")}
                            placeholder={t("placeholders.phone")}
                            fieldName="phone"
                            fieldError={methods.formState.errors.phone}
                            resetServerErrors={resetServerErrors}
                        />
                        <TextInput
                            title={t("fields.email")}
                            placeholder={t("placeholders.email")}
                            fieldName="email"
                            fieldError={methods.formState.errors.email}
                            resetServerErrors={resetServerErrors}
                        />
                        <TextInput
                            title={t("fields.position")}
                            placeholder={t("placeholders.position")}
                            fieldName="position"
                            fieldError={methods.formState.errors.position}
                            resetServerErrors={resetServerErrors}
                        />
                        <TextInput
                            title={t("fields.about")}
                            placeholder={t("placeholders.about")}
                            fieldName="about"
                            fieldError={methods.formState.errors.about}
                            resetServerErrors={resetServerErrors}
                        />
                    </FormEntity>
                    <FlexContainer $flexDirection="column">
                        <ButtonXL
                            variant={methods.formState.isSubmitSuccessful ? "darkTertiary" : "lightSecondary"}
                            type="submit"
                            isLoading={isLoading}
                            disabled={!methods.formState.isDirty || !methods.formState.isValid || isLoading}
                        >
                            {t(`${formText}.button`)}
                        </ButtonXL>
                        {!!serverErrors.length && serverErrors.map((error) => (
                            <ErrorParagraphS key={error}>
                                {error}
                            </ErrorParagraphS>
                        ))}
                    </FlexContainer>

                </FormWrapper>
            </FormProvider>

        </FeedbackBlockContainer>
    );
};

export default FeedbackFormSection;
