import axios, { Method } from "axios";

type OptionsType = {
    method?: Method;
    uri: string;
    headers?: any;
    data?: any;
    params?: any;
}

const _env = window.config || process.env;

const request = async <T>({
    method,
    uri,
    headers,
    data,
    params
}: OptionsType)=> {
    try {
        return await axios<T>({
            method,
            url: `${_env.REACT_APP_API_BASE_URL}/${uri}`,
            params: {
                ...(params || {}),
            },
            data: {
                ...(data || {}),
            },
            headers: {
                ...(headers || {}),
            },
        })
        
    } catch (e) {
        console.warn("Request error", e);
        return Promise.reject(e);
    }
};

export default request;
