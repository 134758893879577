import {Trans, useTranslation} from "react-i18next";
import { ParagraphHero, ParagraphM, ParagraphXL } from "../../../ui/text/ParagraphComponents";
import { SpanBase, SpanXXXL } from "../../../ui/text/SpanComponents";
import { Header1 } from "../../../ui/text/HeaderComponents";
import {
    IntroductionBlockContainer,
    CompanyName,
    Description, DescriptionContainer,
    NumberBlock, NumbersBlocksContainer,
} from "./MainPageIntroductionSectionComponents";


const MainPageIntroductionSection = () => {
    const { t } = useTranslation( "translation", { keyPrefix: "main.introduction"});

    return (
        <IntroductionBlockContainer>
            <CompanyName />
            <DescriptionContainer>
                <Description>
                    <Header1>
                        <Trans
                            t={t}
                            i18nKey="description"
                            components={{ span: <SpanBase $weight={500} $color="white" /> }}
                        />
                    </Header1>
                </Description>
                <NumbersBlocksContainer>
                    <NumberBlock>
                        <ParagraphHero $weight={700}>8</ParagraphHero>
                        <ParagraphXL>{t("onTheMarket")}</ParagraphXL>
                        <ParagraphM $color="neutral_400">{t("rebranding")}</ParagraphM>
                    </NumberBlock>
                    <NumberBlock>
                        <ParagraphHero $weight={700}>
                            80
                            <SpanXXXL>+</SpanXXXL>
                        </ParagraphHero>
                        <ParagraphXL>{t("professionals")}</ParagraphXL>
                        <ParagraphM $color="neutral_400">{t("solutions")}</ParagraphM>
                    </NumberBlock>
                </NumbersBlocksContainer>
            </DescriptionContainer>
        </IntroductionBlockContainer>
    );
};

export default MainPageIntroductionSection;

