import styled from "styled-components";
import { Header2 } from "../../../ui/text/HeaderComponents";
import {motion} from "framer-motion";

export const TechnologiesBlockContainer = styled.section`
    padding: 256rem 40rem 128rem 40rem;
    width: 100%;
    box-sizing: border-box;
    
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.neutral_800};
    font-weight: 700;

    ${({ theme }) => `
        @media ${theme.media.medium} { 
            padding: 128px 16px 64px 16px;
        }
     `};
`;

export const HeaderBlock = styled(Header2)`
    margin-bottom: 64rem;
    text-align: center;
`;

export const TechnologiesItemsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    > :nth-child(2) {
        grid-column: 2 / 4;
    }
    > :nth-child(8), :nth-child(16) {
        grid-column: 4 / 6;
    }
    > :nth-child(9) {
        grid-column: 1 / 3;
    }

    gap: 16rem;

    ${({ theme }) => `
        @media ${theme.media.large} {
            grid-template-columns: repeat(4, 1fr);

            > :nth-child(2) {
                grid-column: unset;
            }
            > :nth-child(8), :nth-child(16) {
                grid-column: unset;
            }
            > :nth-child(9) {
                grid-column: unset;
            }
        }

        @media ${theme.media.medium} {
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        }
        
        @media (max-width: 674px) {
            >:nth-child(16) {
                grid-column: 2 / 3;
            }
        }
        
        @media (max-width: 502px) {
            >:nth-child(16) {
                grid-column: unset;
            }
        }
     `};
`;

export const TechnologyItem = styled.div`
    position: relative;
    
    height: 200rem;
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    background-color: ${({ theme }) => theme.colors.neutral_200};
    border-radius: 16px;
    
    svg.desktop {
        height: 120rem;
        width: 224rem;
    }
    
    svg.mobile {
        display: none;
    }

    @media ${({ theme }) => theme.media.large} {
        height: 160rem;

        svg.desktop {
            height: 120rem;
            width: 224rem;
        }
    }

    @media ${({ theme }) => theme.media.medium} {
        height: 100px;
        min-width: 150px;
        
        svg.desktop {
            display: none;
        }

        svg.mobile {
            display: block;
            height: 100px;
            width: auto;
        }
    }
`;

interface AnimatedTechnologyIconProps {
    $duration: number;
    $index: number;
    $count: number;
}

export const AnimatedTechnologyIcon = styled(motion.div).attrs<AnimatedTechnologyIconProps>((props) =>
({
    animate: {
        opacity: [0, 1, 1, 0],
    },
    transition: {
        duration: props.$duration,
        repeat: Infinity,
        times: [0, 0.1, 0.9, 1],
        delay: props.$index * props.$duration,
        repeatDelay: props.$duration * props.$count,
    },
}))`
    position: absolute;
`;
