import styled from "styled-components";
import { Header2 } from "../../../ui/text/HeaderComponents";
import { ParagraphL } from "../../../ui/text/ParagraphComponents";

export const FeedbackBlockContainer = styled.section`
  padding: 128rem 40rem;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => `
    @media ${theme.media.medium} {
        padding: 96px 16px;
    }
  `};
`;

export const DescriptionBlock = styled(ParagraphL)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral_400};
  max-width: 905rem;

  ${({ theme }) => `
    @media ${theme.media.medium} {
      font-size: 18px;
    }
  `};
`;

export const FeedbackFormTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24rem;

  margin-bottom: 96rem;

  ${({ theme }) => `
    @media ${theme.media.medium} {
      margin-bottom: 64px;
    }
  `};
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormEntity = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60rem;

  & > :nth-child(5) {
    grid-column: span 2;
  }

  margin-bottom: 48rem;

  ${({ theme }) => `
    @media ${theme.media.medium} {
     display: flex;
     flex-direction: column;
     gap: 48px;
     margin-bottom: 48px;
    }
   
  `};
`;
