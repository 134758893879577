import { ButtonHTMLAttributes } from "react";
import Loader from "../loader/Loader";
import { BaseButtonComponent } from "./ButtonComponents";
import { ButtonVariantType } from "./buttonTypes";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant: ButtonVariantType;
    isLoading?: boolean;
};

export const ButtonXL = ({ isLoading, ...props }: ButtonProps) => (
    <BaseButtonComponent {...props} $variant={props.variant} $isLoading={isLoading}>
        {isLoading && <Loader />}
        {props.children}
    </BaseButtonComponent>
)
