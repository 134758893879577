import styled from "styled-components";
import { LoaderVariantType } from "./loaderTypes";

export const BaseLoaderCircle = styled.circle`
  stroke-width: 20;
  fill: none;
  stroke-linecap: round;
`;

export const MainLoaderCircle = styled(BaseLoaderCircle)<{$variant: LoaderVariantType}>`

  stroke-dasharray: 550 630;

  ${({ theme, $variant }) => {
    switch ($variant) {
      case "primary":
        return `
           stroke: ${theme.colors.primary_300};
        `;
      case "lightSecondary":
        return `
            stroke: ${theme.colors.white};
        `;
      default: return "";
    }
  }}
`;

export const BackgroundLoaderCircle = styled(BaseLoaderCircle)<{$variant: LoaderVariantType}>`
  stroke-dasharray: 600 200;

  ${({ theme, $variant }) => {
    switch ($variant) {
      case "primary":
        return `
           stroke: ${theme.colors.primary_300};
           stroke-opacity: 0.2

        `;
      case "lightSecondary":
        return `
            stroke: ${theme.colors.white};
            stroke-opacity: 0.2

        `;
      default: return "";
    }
  }}
`;
