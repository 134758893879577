import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    html {
        font-size: calc(100vw / 1440);

        ${({ theme }) => `
            @media ${theme.media.large} {
                font-size: calc(100vw / 1024);
            }
            
            @media ${theme.media.medium} {
                font-size: calc(100vw / 768);
            }
        `};
    }
    
    * {
        font-family: "Inter", sans-serif;
        margin: 0;
    }
    
    body {
        margin: 0;
        position: relative;
        min-width: 320px;
        background-color: black;
    }
`;
